import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../shared/auth/auth.service";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request);
    const headers = new HttpHeaders({
      "authorization": `${this.authService.getToken()}`,
    });
    const req = request.clone({ headers });
    // console.log(req);
    return next.handle(req);
  }
}
