import { Injectable } from "@angular/core";

import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, of, from } from "rxjs";
import { map, timeout, retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { TokenPayload } from "../../Models/token-payload.model";
import { User } from "../../Models/user.model";
import { ConfigService } from "../services/config.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private token: string;
  public user: User = new User();

  //public picName:string = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private config: ConfigService
  ) {}

  private saveToken(token: string): void {
    if (this.token) {
      this.logout();
    }
    localStorage.setItem("userToken", token);
    this.token = token;
  }

  getToken(): string {
    //console.log(this.token)
    if (!this.token) {
      this.token = localStorage.getItem("userToken");
    }
    return this.token;
  }

  public getUserDetails(): TokenPayload {
    const token = this.getToken();
    let payload;
    if (token) {
      // console.log(token);
      payload = token.split(".")[1];
      //console.log(payload);

      payload = window.atob(payload);
      // console.log(JSON.parse(payload));

      //console.log(payload)

      //this.picName = JSON.parse(payload).image

      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();

    if (localStorage.getItem("support") == "true") return true;
    else if (user) {
      if (user.exp > Date.now() / 1000) {
        //console.log("true");
        return true;
      } else {
        //console.log("false");
        return false;
      }
    } else {
      return false;
    }
  }

  public login(username: string, password: string): Observable<any> {
    return this.httpClient
      .request("post", this.config.apiBaseUrl + "/login", {
        body: { username: username, password: password },
      })
      .pipe(
        timeout(this.config.timeOut),
        map((res: any) => {
          if (!res) return;

          console.log(res);

          if (res.success.token) this.saveToken(res.success.token);

          if (res.success.user) this.user = res.success.user;
          return res;
        }),
        retry(this.config.retry)
      );
  }

  public logout(): void {
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }
}
