import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'filter-expansion-panel',
  templateUrl: './filter-expansion-panel.component.html',
  styleUrls: ['./filter-expansion-panel.component.scss']
})
export class FilterExpansionPanelComponent implements OnInit {


  

  @Output('search') onSearch: EventEmitter<any> = new EventEmitter();

  @Output('reset') onReset: EventEmitter<any> = new EventEmitter();

  @Output('dataModelChanged') onDatamodelChange: EventEmitter<any> = new EventEmitter();

  @Input('allowToChange') allowToChange : boolean = false;

  constructor() { }

  ngOnInit() {
  }


  search(){
    this.onSearch.emit();
  }

  reset(){
    this.onReset.emit();
  }

  change(){
    this.onDatamodelChange.emit();
  }

}
