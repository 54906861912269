import { RouteInfo } from "./sidebar.metadata";


export const ROUTES: RouteInfo[] = [
  // {
  //   path: "/admin/full-layout",
  //   title: "Full Layout",
  //   icon: "ft-layout",
  //   class: "",
  //   badge: "",
  //   badgeClass: "",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "/admin/content-layout",
  //   title: "Content Layout",
  //   icon: "ft-square",
  //   class: "",
  //   badge: "",
  //   badgeClass: "",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path: "/admin",
  //   title: "Menu Levels",
  //   icon: "ft-align-left",
  //   class: "has-sub",
  //   badge: "1",
  //   badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
  //   isExternalLink: false,
  //   submenu: [
  //     {
  //       path: "javascript:;",
  //       title: "Second Level",
  //       icon: "",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: true,
  //       submenu: [],
  //     },
  //     {
  //       path: "",
  //       title: "Second Level Child",
  //       icon: "",
  //       class: "has-sub",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [
  //         {
  //           path: "javascript:;",
  //           title: "Third Level 1.1",
  //           icon: "",
  //           class: "",
  //           badge: "",
  //           badgeClass: "",
  //           isExternalLink: true,
  //           submenu: [],
  //         },
  //         {
  //           path: "javascript:;",
  //           title: "Third Level 1.2",
  //           icon: "",
  //           class: "",
  //           badge: "",
  //           badgeClass: "",
  //           isExternalLink: true,
  //           submenu: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: "/admin/changelog",
  //   title: "ChangeLog",
  //   icon: "ft-file",
  //   class: "",
  //   badge: "",
  //   badgeClass: "",
  //   isExternalLink: false,
  //   submenu: [],
  // },
  // {
  //   path:
  //     "https://pixinvent.com/apex-angular-4-bootstrap-admin-template/documentation",
  //   title: "Documentation",
  //   icon: "ft-folder",
  //   class: "",
  //   badge: "",
  //   badgeClass: "",
  //   isExternalLink: true,
  //   submenu: [],
  // },
  // {
  //   path: "https://pixinvent.ticksy.com/",
  //   title: "Support",
  //   icon: "ft-life-buoy",
  //   class: "",
  //   badge: "",
  //   badgeClass: "",
  //   isExternalLink: true,
  //   submenu: [],
  // },
//   {
//     path: "/admin/notification",
//     title: 'Send Notification',
//     icon: "ft-bell",
//     class: "",
//     badge: "",
//     badgeClass: "",
//     isExternalLink: false,
//     submenu: [],
//   },

  {
    path: "/admin/notification/list",
    title: 'Notifications',
    icon: "ft-bell",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/user/list",
    title: 'Users',
    icon: "ft-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  

  {
    path: "/admin/game/list",
    title: 'Games',
    icon: "ft-box",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/faq",
    title: 'FAQ',
    icon: "ft-rotate-cw",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/terms&conditions",
    title: 'Terms & Conditions',
    icon: "ft-alert-octagon",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },



  {
    path: "/admin/product/list",
    title: 'Products',
    icon: "ft-shield",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/androidBuild",
    title: 'Android Build Info',
    icon: "ft-tablet",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },


  {
    path: "/admin/gameApproval",
    title: 'Game Approvals',
    icon: "ft-settings",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/categoryControl",
    title: 'Categories Control',
    icon: "ft-settings",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/how-to-play",
    title: 'How To Play',
    icon: "ft-alert-circle",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/feature",
    title: 'Features',
    icon: "ft-package",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/admin/gameClick",
    title: 'Game Click',
    icon: "ft-bar-chart-2",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },


  {
    path: "/admin/portalSummary",
    title: 'Portal Summary',
    icon: "ft-bar-chart-2",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },


  {
    path: "/admin/gamePerformance",
    title: 'Game Performance',
    icon: "ft-bar-chart-2",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },


  {
    path: "/admin/exclusive",
    title: 'Exclusive Blocks',
    icon: "ft-bar-chart-2",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },


  

  
];
