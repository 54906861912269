// import { GameListComponent } from './../pages/Game/Components/game-list/game-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';


//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { CustomizerComponent } from './customizer/customizer.component';

import {NotificationService} from './services/notification.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {ModalService} from './services/modal.service';
import {MaterialModule} from '../material.module';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth-guard.service';
import { DialogMessageComponent } from './Components/dialog-message/dialog-message.component';
import { FilterExpansionPanelComponent } from './Components/filter-expansion-panel/filter-expansion-panel.component';
import {FormService} from './services/form.service'
import {JwtInterceptor} from '../Interceptors/jwt.interceptor'
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { FormsModule } from '@angular/forms';
 




@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        FormsModule,
        TranslateModule,
        CustomizerComponent,
        FilterExpansionPanelComponent,
        StopPropagationDirective
        
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule,
        HttpClientModule,
        MaterialModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        // GameListComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        CustomizerComponent,
        DialogMessageComponent,
        FilterExpansionPanelComponent,
        StopPropagationDirective
        
    ],providers:[
        NotificationService,
        ModalService,
        AuthGuard,
        AuthService,
        FormService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor , multi: true }
    ],
    entryComponents:[DialogMessageComponent],
    
})
export class SharedModule { }
