import {Routes, RouterModule} from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: () =>
      import('../../changelog/changelog.module').then((m) => m.ChangeLogModule),
  },
  {
    path: 'full-layout',
    loadChildren: () =>
      import('../../pages/full-layout-page/full-pages.module').then(
        (m) => m.FullPagesModule
      ),
  },
  // {
  //   path: 'full-layout',
  //   loadChildren: () =>
  //     import('../../pages/Game/game.module').then(
  //       (m) => m.GameModule
  //     ),
  // },
  {
    path: 'notification',
    loadChildren: () =>
      import('../../pages/Notification/notification.module').then(
        (m) => m.NotificationModule
      ),
      canActivate : [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () =>
      import(`../../pages/User/user.module`).then((user) => user.UserModule),
      canActivate : [AuthGuard]
  },
  {
    path: 'game',
    loadChildren: () =>
      import(`../../pages/Game/game.module`).then((game) => game.GameModule),
      canActivate : [AuthGuard]
  },

  {
    path: 'faq',
    loadChildren: () =>
      import(`../../pages/Faq/faq.module`).then((faq) => faq.FaqModule),
      canActivate : [AuthGuard]
  },

  {
    path: 'terms&conditions',
    loadChildren: () =>
      import(`../../pages/Terms & Conditions/terms-and-conditions.module`).then(
        (t) => t.TermsAndConditionsModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'product',
    loadChildren: () =>
      import(`../../pages/Product/product.module`).then(
        (p) => p.ProductModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'androidBuild',
    loadChildren: () =>
      import(`../../pages/Android Build/android-build.module`).then(
        (a) => a.AndroidBuildModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'gameApproval',
    loadChildren: () =>
      import(`../../pages/Game Approval/game-approval.module`).then(
        (g) => g.GameApprovalModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'categoryControl',
    loadChildren: () =>
      import(`../../pages/Category Control/category-control.module`).then(
        (cat) => cat.CategoryControlModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'feature',
    loadChildren: () =>
      import(`../../pages/Feature/feature.module`).then(
        (f) => f.FeatureModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'portalSummary',
    loadChildren: () =>
      import(`../../pages/Portal Summary/portal-summary.module`).then(
        (p) => p.PortalSummaryModule
      ),
      canActivate : [AuthGuard]
  },


  {
    path: 'gameClick',
    loadChildren: () =>
      import(`../../pages/Game Click/game-click.module`).then(
        (game) => game.GameClickModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'gamePerformance',
    loadChildren: () =>
      import(`../../pages/Game Performance/game-performance.module`).then(
        (game) => game.GamePerformanceModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'exclusive',
    loadChildren: () =>
      import(`../../pages/Exclusive Block/exclusive-block.module`).then(
        (e) => e.ExclusiveBlockModule
      ),
      canActivate : [AuthGuard]
  },

  {
    path: 'how-to-play',
    loadChildren: () =>
      import(`../../pages/how-to-play/how-to-play.module`).then(
        (e) => e.HowToPlayModule
      ),
      canActivate : [AuthGuard]
  },
];
