import { Injectable } from '@angular/core';
import { TemplateConfig } from '../template-config/config.interface';
import { NgForm } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormService {


    constructor( ) {
    }


    public validateForm(forms) {
        let isValid = true;
    
        for (let form of forms) {
          isValid = form.valid;
    
          if (!isValid) {
            this.fireInvalidFormControls(form);
            return isValid;
          }
        }
    
        return isValid;
      }
    
      public fireInvalidFormControls(form: NgForm) {
        if (!form) return;
    
        Object.keys(form.controls).forEach((key) => {
          if (!form.controls[key].valid) form.controls[key].markAsTouched();
        });
      }

   



}
