import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[stop-propagation]',
})
export class StopPropagationDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    console.log(event)
    event.preventDefault();
    event.stopPropagation();
    console.log('Click from Host Element!');
  }
}
