import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  apiBaseUrl: string = "";
  constructor(private httpClient: HttpClient) {
    this.apiBaseUrl = "http://adminapi.playit.mobi/api";
  }

  getNotificationData(): Observable<any> {
    return this.httpClient
      .request("get", this.apiBaseUrl + "/notification/data")
      .pipe(
        map((res: any) => {
          if (!res) return;
          console.log("res");
          console.log(res);
          let vendors: any[];
          let games: any[];
          let users: any[];
          console.log("res.data");
          games = res.data.games;
          users = res.data.users;
          vendors = res.data.vendors;

          //console.log(games);

          return { games: games, users: users, vendors: vendors };
        })
      );
  }

  sendNotification(dataModel, file): Observable<any> {
    console.log(file);
    console.log(dataModel.onlineUsers);

    const formData: FormData = new FormData();
    if (file !== undefined) {
      formData.append("image", file, file.name);
    }
    formData.append("title", JSON.stringify(dataModel.title));
    formData.append("body", JSON.stringify(dataModel.body));
    formData.append("games", JSON.stringify(dataModel.games));
    formData.append("priority", JSON.stringify(dataModel.priority));
    formData.append(
      "notificationType",
      JSON.stringify(dataModel.notificationType)
    );
    formData.append("onlineUsers", JSON.stringify(dataModel.onlineUsers));

    return this.httpClient
      .request("post", this.apiBaseUrl + "/notification/fire", {
        body: formData,
      })
      .pipe(
        map((res) => {
          console.log(res);
          if (!res) return;

          return res;
        })
      );
  }
}
