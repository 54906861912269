import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { HowToPlayComponent } from './pages/how-to-play';
import { GameListComponent } from './pages/Game';
 
const appRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'full-layout',
  //   pathMatch: 'full',
  // },
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full"
  },

  {
    path: "admin",
    component: FullLayoutComponent,
    // component: GameListComponent,

    data: { title: 'full Views' },
    children: Full_ROUTES,

  },

  
  { path: 'admin', 
  component: ContentLayoutComponent, 
  data: { title: 'content Views' }, 
  children: CONTENT_ROUTES, 
  canActivate: [AuthGuard] 
},


  {
    path: "auth",
    loadChildren: () =>
      import(`./pages/Auth/auth.module`).then(auth => auth.AuthModule),

  },

 /* {
    path: "notification",
    loadChildren: () =>
      import(`./pages/Notification/notification.module`).then(notfi => notfi.NotificationModule),

  },*/


  // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
